.App {
  text-align: center;
}

form {
  margin: 20px;
}

label {
  display: block;
  margin: 10px 0;
}

button {
  margin: 20px 0;
}

.summary {
  margin-top: 20px;
  font-size: 1.2em;
}

/* Estilo para mostrar o texto apenas quando hover */
.hover-text {
  display: none;
}

label:hover .hover-text {
  display: block;
}
